import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { backgroundColors, colors } from 'styles/colors'
import { IconListQuestion } from 'components/IconListQuestion'
import { displayWidth } from 'styles/width'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { Title } from 'components/TitleComponent'
import { getDataByLanguage } from 'utils/getDataByLanguage'

import { indent } from 'styles/indent'
import { IItem } from 'types/interfaces'
import { LeftSidebar, RightSidebar } from 'components/SideBar'

const ProjectStructureWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${backgroundColors.promotion};
    position: relative;
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: 1px solid ${colors.dark};
    }
    ${mobileAfterBorder}
`
const IconListStyled = styled(IconListQuestion)`
    border-bottom: 1px solid ${colors.dark};
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
    }
`

const HeroColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.dark};
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
        justify-content: center;
        border-right: none;
        align-items: center;
    }
`

const Price = styled.span`
    color: ${colors.accentText};
    font-size: 28px;
    font-weight: bold;
    @media (min-width: ${displayWidth.desktop}) {
        font-size: 36px;
    }
`

const TitleStyled = styled(Title)`
    font-size: 26px;
    margin: 20px;
    font-weight: bold;
    @media (min-width: ${displayWidth.tablet}) {
        margin-left: ${indent.heroColumnTablet};
    }
    @media (min-width: ${displayWidth.desktop}) {
        max-width: 1000px;
        font-size: 32px;
    }
`
const IconListWrapper = styled.div<{ background: string }>`
    background-color: #f7dfd7;
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px 16px;
    box-sizing: border-box;
    z-index: 1;
    @media (min-width: ${displayWidth.tablet}) {
        padding: 46px 10px 46px 30px;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 0px 48px 40px;
    }
`
const IconItem = styled.div`
    /* flex-direction: column; */
    width: 100%;
    padding: 20px 16px;
    box-sizing: border-box;
    align-items: center;
    @media (min-width: ${displayWidth.tablet}) {
        width: 100%;
        align-items: flex-start;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 10px 16px;
    }
`
const Text = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.4px;
    p {
        text-align: left;
        margin-left: 30px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        text-align: left;
    }
`
const Text2 = styled.span`
    display: flex;
    text-align: center;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.4px;
    position: relative;
    margin: 10px 0;
    border-left: 8px solid ${colors.accentText};
    p {
        display: flex;
        align-items: center;
        font-size: 24px;
        line-height: 26px;
        margin-left: 15px;
        min-height: 46px;
        text-align: left;
        box-sizing: border-box;
    }
`
export const StyleQuestion = ({
    id,
    data,
}: {
    id?: string
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data?: any
}) => {
    const { i18n } = useTranslation()

    const projectStructureData = getDataByLanguage(
        data.allShortQuestionYaml,
        i18n.language
    )
    const { title, itemsNew, promo } = projectStructureData

    return (
        <ProjectStructureWrapper id={id}>
            <LeftSidebar background={backgroundColors.promotion} />
            <Container columns={'1fr'} tabletColumns={'1fr 2fr'}>
                <HeroColumn>
                    <TitleStyled>
                        <Price>{promo}</Price> {title}
                    </TitleStyled>
                </HeroColumn>
                <IconListStyled
                    items={itemsNew}
                    fill={backgroundColors.promotion}
                />
            </Container>
            <RightSidebar />
        </ProjectStructureWrapper>
    )
}

export const StyleQuestionDesktop = ({
    id,
    data,
}: {
    id?: string
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data?: any
}) => {
    const { i18n } = useTranslation()

    const projectStructureData = getDataByLanguage(
        data.allShortQuestionYaml,
        i18n.language
    )
    const { title, itemsNew, promo } = projectStructureData

    return (
        <ProjectStructureWrapper id={id}>
            <LeftSidebar background={backgroundColors.promotion} />
            <Container columns={'1fr'} tabletColumns={'1fr'}>
                <HeroColumn>
                    <TitleStyled>
                        <Price>{promo}</Price> {title}
                    </TitleStyled>
                </HeroColumn>
                <IconListWrapper background={backgroundColors.promotion}>
                    {itemsNew.map((item: IItem, index: number) => {
                        return (
                            <IconItem key={index}>
                                <Text2
                                    dangerouslySetInnerHTML={{
                                        __html: item.question,
                                    }}
                                />

                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: item.answer,
                                    }}
                                />
                            </IconItem>
                        )
                    })}
                </IconListWrapper>
            </Container>
            <RightSidebar />
        </ProjectStructureWrapper>
    )
}
